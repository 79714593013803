export const SHIPPING_ORDER = 'shipping_order';
export const PICKUP_ORDER = 'pickup_order';
export const DELIVERY_TYPES = [SHIPPING_ORDER, PICKUP_ORDER];

export const ORDER_WITHOUT_USER = 'without_user';
export const ORDER_WITH_USER = 'with_user';
export const ORDER_USER = [ORDER_WITHOUT_USER, ORDER_WITH_USER];
export const DEFAULT_USER_ID = 108950;

export const ORDER_TYPES = ['sampling', 'giveaway', 'replacement', 'internal_usage'];

export const AVAILABLE_SHIPPING_METHODS = ['freeshipping_freeshipping'];

export const AVAILABLE_PAYMENT_METHODS = ['free'];

export const BLACKLIST_NON_SELECTABLE = [
	'BO-0001',
	'ON-1003',
	'ON-1003ES',
	'ON-1004',
	'CR-0001',
	'VI-VL',
	'TK-VX',
	'TK-VX-AI',
	'TK-VX-GD',
	'TK-VX-AI-EB',
	'TK-VX-GD-EB',
	'TK-VL',
	'TK-VL-AI',
	'TK-VL-GD',
	'TK-VL-AI-EB',
	'TK-VL-GD-EB',
	'CB-1001',
	'CB-1002',
	'CB-1003',
	'G-ZL-1001',
	'G-ZL-1007',
	'G-BR-1001',
	'G-BR-1002',
	'G-BR-1003',
	'G-BR-1006',
	'G-BR-1008',
	'G-BR-1010',
	'G-VR-1001',
	'VP-CB',
	'VP-BR',
	'VP-ZL',
	'VP-UT',
	'VP-PL',
	'VP-BY',
	'VP-LV',
	'LT-BR-A',
	'LT-ZL-A',
	'LT-BY-A',
	'UF-0001',
	'AF-001',
	'MP-003',
	'MP-002',
	'TK-VIT-CO-5N',
	'MP-001',
	'TK-VIT-CO-3N',
	'TK-VIT-CO-4N',
	'TK-VIT-SI-5N',
	'TK-VIT-SI-4N',
	'TK-VIT-SI-3N',
	'TF-0001',
	'TN-SS',
	'TK-VX22-NR-EB',
	'TK-VX22-CE',
	'TK-VX22-CO',
	'TK-VX22-SE',
	'TK-VX22-SI',
	'TK-VS-GA',
	'TK-VX22-NR',
	'VR-1001',
	'TN-SD-A',
	'BR-1006',
	'PL-1009',
	'VST-10',
	'NBD-SCH-1013',
	'NBD-SCH-1014',
];

export default {};
